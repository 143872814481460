/* Utility: Text promo
-------------------------------------------------- */
.card.promo.card-img-sm figure {
    width: 25%;
}

.card.promo.card-img-md figure,
.card.promo.card-img-full.card-compact figure {
    width: 33%;
}

.card.promo.card-img-lg figure {
    width: 50%;
}

.card.promo.card-img-full.card-compact figure {
    float: left;
    padding-right: 5px;
}

.card.promo.card-img-full figure {
    width: 100%;
    max-width: 100%;
    float: none;
    margin: 0 0 10px 0;
    padding-right: 0;
}

@media (min-width: 992px) {
    .card.promo.card-img-full.card-compact figure {
         width: 100%;
         float: none;
    }
}